import React from "react";
import ProductTemplate from "./product";
// import { FiArrowRightCircle } from "react-icons/fi";
//import Image from "@components/common/CloudinaryImage";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
//import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                {/** Qué es¿? */}
                <Title>Webinar per Guàrdia Urbana de Badalona</Title>
                <Text>
                    Arriba un curs online de 3 hores molt diferent.
                    <br />
                    <br />
                    Tots els conceptes claus de les 2 convocatòries de la Guàrdia Urbana de Badalona
                    2022.
                    <br />
                    <br />
                    Un format ràpid, i directe,on tractarem tots els conceptes més importants i
                    susceptibles de sortir en els 2 exàmens.
                    <br />
                    <br />
                    El dijous 20 d'octubre de 18.00 a 21.00 a través de ZOOM.
                    <br />
                    <br />
                    <strong>
                        A més per a tots els alumnes en finalitzar us enviarem un PDF amb tots els
                        conceptes.
                    </strong>
                    <br />
                    <br />
                    Estem segurs que les millors notes sortiran del nostre WEBINAR.
                    <br />
                    <br />
                    Per només 24,99 euros
                </Text>
            </div>
        </ProductTemplate>
    );
};
export default Product;
